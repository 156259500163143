import React from "react"
import Layout from "../components/Layout"
import Title from "../components/Title"
import { graphql } from "gatsby"
import Block from "../components/Block"
import styled from "styled-components"



const Articulos = ({ data }) => {
  let artTitle = ""
  const { edges } = data.allContentfulBlogPost
  const { node_locale } = edges[0].node
  node_locale === "en" ? (artTitle = "Articles") : (artTitle = "Articulos")

  return (
    <>
      <Title title="Articulos"></Title>
      <Layout>
        <Title title={artTitle}></Title>
        <Section>
          <ArticlesCenter>
            {edges.map((dataSet, index) => {
              const { id } = dataSet.node
              const { title } = dataSet.node
              const { description } = dataSet.node.description
              const { fluid } = dataSet.node.heroImage
              const { slug } = dataSet.node
              return (
                <Block
                  key={id}
                  title={title}
                  desc={description}
                  image={fluid}
                  slug={slug}
                ></Block>
              )
            })}
          </ArticlesCenter>
        </Section>
      </Layout>
    </>
  )
}

export default Articulos

const Section = styled.section`
  padding: 5rem 0;
`

const ArticlesCenter = styled.div`
  width: 90vw;
  height: 100%;
  margin: 0 auto;
  max-width: 1170px;
  @media screen and (min-width: 576px) {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(368px, 1fr));
    column-gap: 2rem;
  }
  @media screen and (min-width: 992px) {
    width: 95vw;
  }
`

export const query = graphql`
  query getArticle($locale: String) {
    allContentfulBlogPost(
      sort: { fields: publishDate }
      filter: { node_locale: { eq: $locale } }
    ) {
      edges {
        node {
          id
          title
          node_locale
          publishDate
          slug
          heroImage {
            fluid {
              src
            }
            node_locale
          }
          description {
            description
          }
        }
      }
    }
  }
`
